import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import Content, { HTMLContent } from 'components/Content'
import SEO from 'components/SEO'
import NonStretchedImage from 'components/NonStretchedImage'
import { ActionLink } from 'components/ActionLink'
import { Text } from 'components/Typography'
import { Date } from 'components/News/Date'

import { Flex, Box } from 'components/Grid'
import Container from 'components/Container'

export const ResourceTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  image,
  date,
  file,
}) => {
  const PostContent = contentComponent || Content

  return (
    <Container as="section" my={5} mx="auto" px={[3, 0]} maxWidth={1150}>
      <Flex mt={5} color="textColor" flexDirection={['column-reverse', 'row']}>
        <Flex mr={[0, 3]} mt={[3, 0]} flexDirection="column" width={[1, 1 / 2]}>
          <Box>
            <Text mb={2} fontWeight={700}>
              {title}
            </Text>
            <Date date={date} tags={tags} />
            <PostContent my={2} content={content} />
          </Box>
          <ActionLink
            label="Свали PDF"
            linkURL={file.publicURL}
            color="colors.orange"
            download
          />
        </Flex>
        <Flex width={[1, 1 / 2]} justifyContent="center">
          <Box width={1}>
            <NonStretchedImage
              fluid={image.childImageSharp.fluid}
              alt={title}
            />
          </Box>
        </Flex>
      </Flex>
    </Container>
  )
}

ResourceTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.array,
  image: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
}
ResourceTemplate.defaultProps = {
  tags: [],
}

const ResourcePage = ({ data }) => {
  const { markdownRemark: post } = data
  const { footerData = {}, headerData = {} } = data

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <ResourceTemplate
        content={post.html}
        contentComponent={HTMLContent}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        date={post.frontmatter.date}
        file={post.frontmatter.file}
      />
    </Layout>
  )
}

ResourcePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
}

export default ResourcePage

export const pageQuery = graphql`
  query ResourcePageByID($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        description
        tags
        file {
          publicURL
        }
      }
    }
  }
`
